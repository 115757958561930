<template>
  <div class="noSel" v-loading="loading" style="width:1200px;margin:0 auto;">
    <div class="details_header flex a_c">
      <div class="left">
        <div class="text">{{shopInfo.status_text}}</div>
        <div style="width:167px;">
          <div class="bt" @click="buyAga">再次购买</div>
        </div>
      </div>
      <div class="right">
        <el-steps :active="orderStep" :space="240" finish-status="success" align-center>
          <el-step title="提交订单" :description="submitTime"></el-step>
          <el-step title="支付订单" :description="payTime"></el-step>
          <el-step title="仓库发货" :description="deliverTime"></el-step>
          <el-step title="已收货" :description="receiveTime"></el-step>
        </el-steps>
      </div>
    </div>
    <div class="logisticsHeader">物流信息</div>
    <div class="logisticsBox">
      <div class="logisticsBox_top flex a_c">
        <span v-show="expressList[0].data.length == 0" style="padding:0 20px;">暂无包裹信息!</span>
        <div
          v-show="expressList[0].data.length != 0"
          v-for="(item , i) in expressList"
          :key="i"
          :class="{on:i == logisticBoxChose}"
          @click="changeLogistic(i)"
        >包裹-{{item.express_no}}</div>
      </div>
      <div v-show="expressList.length != 0" style="padding:20px 0;">
        <div
          class="infoText flex a_c"
          v-for="(item,i) in expressList[logisticBoxChose].data"
          :key="i"
        >
          <div class="time">{{item.ftime}}</div>
          <div class="text">{{item.context}}</div>
        </div>
      </div>
    </div>
    <div class="orderInfo">
      <div class="header">订单信息</div>
      <div class="contant">
        <div>支付方式：支付宝</div>
        <div>订单编号：{{shopInfo.order_id}}</div>
        <div>下单时间：{{shopInfo.ctime}}</div>
        <div>下单地址：{{shopInfo.delivery.address}}</div>
        <div>收件人信息：{{shopInfo.delivery.contact}} {{shopInfo.delivery.mobile}}</div>
      </div>
    </div>
    <div class="orderInfo">
      <div class="header">商品信息</div>
      <div class="shopInfoText flex a_c">
        <span style="width:110px;"></span>
        <span style="width:400px;">商品详情</span>
        <span style="width:320px;">单价</span>
        <span style="flex:1;">数量</span>
      </div>
      <div class="shopInfoList flex a_c" v-for="(item,i) in shopInfo.items" :key="i">
        <div style="width:110px;">
          <img width="90px" height="90px" :src="item.goods_img" alt />
        </div>
        <div class="infoName" style="width:400px;">
          <div>{{item.goods_title}}</div>
          <div>{{item.sku_title}}</div>
        </div>
        <div style="width:320px;text-align:center;">￥{{item.price}}</div>
        <div style="flex:1;text-align:center;">{{item.number}}</div>
      </div>
    </div>
    <div class="shopPriceAll">
      <div>
        商品总计：￥
        <span style="font-size:14px;">{{shopInfo.goods_price}}</span>
      </div>
      <div>
        运费总计：￥
        <span style="font-size:14px;">0.00</span>
      </div>
    </div>
    <div class="shopPriceAll2" style="height">
      实际总额：
      <span style="color:#FF2742;font-size:14px">￥{{shopInfo.final_price}}</span>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import storage from "@/utils/storage";
export default {
  data() {
    return {
      orderStep: 1,
      logisticBoxChose: 0, //包裹选择index值
      expressList: [
        {
          express_no: "",
          state: "",
          state_str: "",
          data: [],
          express: ""
        }
      ],
      shopInfo: {
        delivery: {}
      },
      loading: false,
      submitTime: "2121-06-12  12:23:12",
      payTime: "2121-06-12  12:23:12",
      deliverTime: "2121-06-12  12:23:12",
      receiveTime: "2121-06-12  12:23:12"
    };
  },
  created() {
    this.fixType();
    this.getDetailsRes();
    this.getLogInfo();
  },
  computed: {},
  components: {},
  methods: {
    //再次购买
    buyAga() {
      var settlementInfo = {};
      var goods_params = [],
        goods_info = [];
      var shop = this.shopInfo.items;
      shop.forEach(item => {
        goods_params.push({
          id: item.goodsid,
          number: item.number,
          skuid: item.skuid
        });

        goods_info.push({
          title: item.goods_title,
          skuTitle: item.sku_title,
          pic: item.goods_img,
          price: item.price,
          number: item.number,
          skuid: item.skuid
        });
      });

      settlementInfo.goods_params = goods_params;
      settlementInfo.goods_info = goods_info;
      settlementInfo.buy_type = 1;
      storage.set("settlementInfo", settlementInfo, true);
      this.routerTo("settlement");
    },
    //选择包裹
    changeLogistic(i) {
      this.logisticBoxChose = i;
    },
    //获取搜索结果数据
    getDetailsRes() {
      this.loading = true;
      var params = {
        order_id: this.$route.params.order_id
      };
      api
        .$getOrderDetail(params)
        .then(res => {
          console.log(res.data);
          if (res.data.code == 0) {
            this.shopInfo = res.data.data;
            this.submitTime = res.data.data.ctime;
            this.payTime = res.data.data.pay_time;
            this.deliverTime = res.data.data.stime;
            this.receiveTime = res.data.data.etime;
            switch (res.data.data.status_text) {
              case "待支付":
                this.orderStep = 1;
                break;
              case "交易取消":
                this.orderStep = 1;
                break;
              case "待发货":
                this.orderStep = 2;
                break;
              case "待收货":
                this.orderStep = 3;
                break;
              default:
                this.orderStep = 4;
            }
          }
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    },
    //获取物流信息
    getLogInfo() {
      var params = {
        order_id: this.$route.params.order_id
      };
      api
        .$getLogInfo(params)
        .then(res => {
          console.log(res.data);
          if (res.data.data.length != 0) {
            this.expressList = this.data.data.deliveryList[0].expressList;
          } else {
            this.expressList = [
              {
                data: []
              }
            ];
          }
          // if(res.data.code == 0){
          //   this.shopInfo = res.data.data
          // }
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    },
    //固定type栏状态
    fixType() {
      this.$store.commit("saveTypeIndex", 9);
    },
    //二级路由跳转
    routerTo(value) {
      this.$router.push({ name: value });
    }
  }
};
</script>

<style scoped>
.details_header {
  box-sizing: border-box;
  height: 170px;
  padding: 20px 0;
}

.details_header .left {
  width: 195px;
  height: 130px;
  border-right: 1px solid #cdcdcd;
}
.details_header .right {
  width: 1000px;
}
.details_header .left .text {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  width: 167px;
  text-align: center;
  line-height: 78px;
  height: 78px;
}

.details_header .left .bt {
  width: 78px;
  height: 27px;
  background: #666666;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
  line-height: 27px;
  text-align: center;
  margin: 0 auto;
}
.logisticsHeader {
  height: 57px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 57px;
}
.logisticsBox {
  width: 1200px;
  border: 1px solid #999999;
}
.logisticsBox_top {
  height: 78px;
  background: #dcdcdc;
}
.logisticsBox_top div {
  padding: 0 15px;
  height: 38px;
  background: #ffffff;
  border-radius: 19px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  text-align: center;
  line-height: 38px;
  margin: 0 10px;
  cursor: pointer;
}
.logisticsBox_top .on {
  background: #000000;
  color: #ffffff;
}
.infoText {
  min-height: 26px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
}
.infoText .time {
  color: #999999;

  width: 167px;
  padding-left: 10px;
  box-sizing: border-box;
}

.infoText .text {
  padding-left: 10px;
  width: 950px;
  border-left: 1px solid #cdcdcd;
}
.orderInfo {
  width: 1200px;
  border: 1px solid #999999;
  margin-top: 60px;
}
.orderInfo .header {
  height: 80px;
  background: #dcdcdc;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 80px;
  padding-left: 10px;
}
.orderInfo .contant {
  padding: 10px;
}
.orderInfo .contant div {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  padding: 5px 0;
}
.shopInfoText span {
  height: 58px;
  display: inline-block;
  text-align: center;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 58px;
}
.shopInfoList {
  height: 90px;
  margin-bottom: 20px;
  padding-left: 10px;
}

.shopInfoList > div .infoName {
  width: 320px;
  text-align: left !important;
}
.shopPriceAll {
  box-sizing: border-box;
  height: 72px;
  text-align: right;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  padding: 20px 10px;
  line-height: 22px;
}
.shopPriceAll2 {
  text-align: right;
  padding-right: 10px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-bottom: 500px;
}
</style>

