import http from '@/utils/http'
console.log(http)
export default {
    //登录
    $login(params) {
        return http.get('/list', params)
    },
    //获取轮播商品列表
    $getSwiperList() {
        return http.get('/list')
    },
    //获取首页商品列表
    $shopList() {
        return http.get('/list')
    },
    //个人中心-全部订单
    $getAllOrders() {

    },
    //用户获取验证码
    $getVerCode(params) {
        return http.post('/user_api/user/send_code', params)
    },
    //用户登录
    $userLog(params) {
        return http.post('/user_api/user/login_from_webpage', params)
    },
    //获取商品
    $getShopList(params) {
        return http.get('/user_api/goods/list', params)
    },
    //获取品牌分类
    $getCats(params) {
        return http.get('/user_api/goods/cats', params)
    },
    //获取省、市、区列表
    $getChina(params) {
        return http.get('/user_api/address/china', params)
    },
    //获取地址列表
    $getAddList(params) {
        return http.get('/user_api/address/list', params)
    },
    //新增地址
    $addRessChange(params) {
        return http.post('/user_api/address/update', params)
    },
    //删除地址
    $delAddress(params) {
        return http.post('/user_api/address/delete', params)
    },
    //设置默认地址
    $setDefault(params) {
        return http.post('/user_api/address/set_default', params)
    },
    //商品详情
    $getGoodsDetails(params) {
        return http.get('/user_api/goods/detail', params)
    },
    //创建订单
    $createOrder(params) {
        return http.post('/user_api/order/create', params)
    },
    //拿到支付二维码
    $js_pay(params) {
        return http.post('/user_api/order/js_pay', params)
    },
    //获取订单列表
    $getOrderList(params) {
        return http.get('/user_api/order/list', params)
    },
    //获取订单状态数量
    $getOrderNum() {
        return http.get('/user_api/order/num_status')
    },
    //获取订单详情
    $getOrderDetail(params) {
        return http.get('/user_api/order/detail', params)
    },
    //获取物流详情
    $getLogInfo(params) {
        return http.get('/user_api/order/showExpress', params)
    },
    //取消订单
    $cancel(params) {
        return http.post('/user_api/order/cancel', params)
    },
    //确认收货
    $receipt(params) {
        return http.post('/user_api/order/receipt', params)
    },
    //获取用户问题分类
    $FAQ_cate_list() {
        return http.get('/user_api/common/FAQ_cate_list')
    },
    //获取问题列表
    $FAQ_list(params) {
        return http.get('/user_api/common/FAQ_list', params)
    },
    //获取支付结果
    $getPayResult(params) {
        return http.get('/user_api/order/check_pay', params)
    }
}